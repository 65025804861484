import { render, staticRenderFns } from "./code-glitch-effect.vue?vue&type=template&id=0dbe0115&scoped=true&"
import script from "./code-glitch-effect.vue?vue&type=script&lang=js&"
export * from "./code-glitch-effect.vue?vue&type=script&lang=js&"
import style0 from "./code-glitch-effect.vue?vue&type=style&index=0&id=0dbe0115&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dbe0115",
  null
  
)

export default component.exports