import { render, staticRenderFns } from "./big-circle.vue?vue&type=template&id=725bb391&scoped=true&"
import script from "./big-circle.vue?vue&type=script&lang=js&"
export * from "./big-circle.vue?vue&type=script&lang=js&"
import style0 from "./big-circle.vue?vue&type=style&index=0&id=725bb391&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725bb391",
  null
  
)

export default component.exports